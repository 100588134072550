<template>
    <div>
        <div class="w-[50%] ml-[50%] px-3 mb-2 flex justify-between items-center" style="margin-left: 45%; padding-left: 5%; border-left: 3px solid #2196f3">
            <h4 class="projectTitle">
                Project <br />
                <span class="label-span">{{ getProjectName(candidate?.projectId) }}</span>
            </h4>
        </div>
        <div class="dataContainer w-full h-full" style="background: red; width: 100%; height: 100%">
            <div>
                <div style="border-bottom: 1px solid #f5f6f6; padding: 5%">
                    <span class="profile-name">{{ candidateInfo?.Last_name + " " + candidateInfo?.First_name }}</span>
                    <br />
                    <span class="email" @click="copyEmail">{{ candidateInfo?.Email }} <img loading="lazy" decoding="async" src="@/assets/Images/icons/copy-icon.svg" alt="copy-icon" /></span>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import jsPDF from "jspdf";
import { useStore } from "../store/index";

export default {
    name: "TalentDataDownload",
    props: {
        candidateInfo: Object,
        candidate: Object,
        candidateInterpretations: Array,
    },
    setup() {
        const store = useStore();
        return { store };
    },
    methods: {
        getProjectName(projectId) {
            const project = this.store.projects?.find((project) => project._id === projectId);
            return `${project?.jobTitle} - ${project?.seniority} - ${project?.name}`;
        },
        handleDownloadPDF() {
            const doc = new jsPDF();
            const content = this.$refs.pdfContent.innerHTML;

            // Set text color to black
            doc.setTextColor(0, 0, 0);

            doc.html(content, {
                callback: function (doc) {
                    doc.save("talent_data.pdf");
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.profile-name {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 14px;
    color: #000000; /* Set text color to black */
}
.dataContainer {
    background-color: #f5f5f6;
    width: 100%;
    height: 100%;
    > :first-child {
        margin-top: 20px;
        width: 80%;
        height: 80%;
        margin: 5% 10%;
    }
}
.email {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px; /* Adjust font size */
    color: #000000; /* Set text color to black */
    img {
        margin-left: 10px;
    }
}
.projectTitle {
    font-family: "DM Sans";
    font-weight: 600;
    font-size: 12px; /* Adjust font size */
    color: #000000; /* Set text color to black */
    margin-bottom: 20px;
    margin-left: 20px;
    span {
        font-weight: 500;
        font-size: 10px; /* Adjust font size */
        color: #000000; /* Set text color to black */
        margin-left: 20px;
    }
}
</style>
