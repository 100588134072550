<template>
    <div class="w-full flex flex-col lg:flex-row justify-between items-center px-[5%] lg:px-[8%] relative bg-[#F5F8FF] overflow-hidden bg-opacity-80 relative">
        <!-- <div class="absolute top-0 left-0 w-full h-full bg-[#F5F8FF] -z-20"></div> -->
        <div class="absolute top-[20%] left-[-50%] z-[-1]">
            <svg width="1077" height="1139" viewBox="0 0 1077 1139" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1076.62 569.214C1076.62 883.395 835.679 1138.05 538.5 1138.05C241.321 1138.05 0.375 883.395 0.375 569.214C0.375 255.033 241.321 0.375 538.5 0.375C835.679 0.375 1076.62 255.033 1076.62 569.214Z"
                    stroke="#FF725E"
                    stroke-width="0.75"
                />
            </svg>
        </div>
        <div class="flex flex-col w-full lg:w-[50%] gap-5 items-start text-center lg:text-left">
            <h3 class="text-3xl font-bold">Talent aquisition services</h3>

            <h1 class="uppercase font-[700] text-[46px] text-[#343637]">
                <span class="relative w-[fit-content] inline-block">
                    <span class="absolute top-0 left-0 w-full h-full bg-[#FF6969] -rotate-[6deg]"></span>
                    <span class="relative z-5">0%</span>
                </span>
                Interests Rate.
            </h1>
            <p class="text-[14px] text-left">
                Sed placerat convallis aenean fermentum. Aliquet eget feugiat sed consectetur sodales eleifend.<br />
                Libero varius duis adipiscing dictum at amet.
            </p>
            <router-link
                to="/request-service"
                class="text-[#fff] z-3 font-[700] my-[5%] bg-[#3361FF] px-4 py-2 rounded-[15px] border border-[#3361FF] hover:bg-transparent hover:text-[#3361FF] cursor-pointer duration-300"
                >Request Service</router-link
            >
            <div class="w-full flex flex-col gap-5 text-left">
                <div class="w-full md:w-[80%] uppercase text-[#3361FF] bg-[#fff] rounded-md flex justify-between items-center font-[600] px-4 py-4 hover:scale-110 duration-300">
                    <p>No hidden fees</p>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </div>
                <div class="w-full md:w-[80%] uppercase text-[#3361FF] bg-[#fff] rounded-md flex justify-between items-center font-[600] px-4 py-4 hover:scale-110 duration-300">
                    <p>Startup and growth friendly</p>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </div>
                <div class="w-full md:w-[80%] uppercase text-[#3361FF] bg-[#fff] rounded-md flex justify-between items-center font-[600] px-4 py-4 hover:scale-110 duration-300">
                    <p>Comprehensive job listings</p>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </div>
            </div>
        </div>
        <div class="flex items-end relative">
            <div class="absolute top-10 lg:top-[150px] -left-5 lg:-left-10">
                <div class="px-6 py-4 rounded-[20px] whitespace-nowrap bg-[#E0DBFF] text-[#343637]">160+ new job vacancies</div>
                <div class="px-6 py-4 mt-[3%] rounded-[20px] whitespace-nowrap bg-[#E0DBFF] text-[#343637]">95% satisfied clients</div>
            </div>
            <img class="ml-[10%] md:ml-[2%]" src="@/assets/landing-page-logos/talentAq.png" alt="" />
        </div>
        <div class="absolute right-[-200px] bottom-[-228px]">
            <svg width="456" height="456" viewBox="0 0 456 456" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="228" cy="228" r="228" fill="#E0DBFF" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "TalentAquisition",
};
</script>
