<template>
    <transition name="show">
        <div class="topbar" v-if="open">
            <button @mouseover="$emit('Opennav')">
                <img loading="lazy" decoding="async" src="@/assets/Images/share.svg" alt="Follow us" />
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    name: "TopBar",
    props: ["open"],
};
</script>

<style lang="scss" scoped>
.topbar {
    align-self: center;
    position: fixed;
    top: 50%;
    z-index: 40;
    left: 0.5rem;
}
.topbar > button {
    background-color: transparent;
    border: none;
}
.show {
    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(-60px);
    }
    &-enter-active,
    &-leave-active {
        transition: all 500ms;
    }
}
</style>
