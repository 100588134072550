<template>
    <div class="w-80 h-[28rem]" @mouseleave="flipCard">
        <transition name="flip">
            <div
                v-if="!flipped"
                :key="talent.Email + '_front'"
                class="w-full h-full rounded-lg bg-white will-change-transform relative overflow-hidden flex flex-col items-start justify-between group hover:scale-105 duration-1000 transition-all ease-out shadow-card inner"
            >
                <!-- <img loading="lazy"  decoding="async"  :src="talent.avatar? talent.avatar:'@/assets/GO_logo.svg'" alt=""> -->
                <img
                    loading="lazy"
                    :src="getImgUrl(talent?.Avatar)"
                    alt="GO Platform Talent"
                    class="w-full h-2/5 mb-2 object-fill grayscale group-hover:grayscale-0 group-hover:object-cover duration-700 ease-in-out transition-all"
                />
                <h1 class="w-full text-left px-5 text-xl font-semibold text-slate-700">{{ talent?.First_name + " " + talent?.Last_name }}</h1>
                <h2 class="w-full text-left px-5 text-sm font-light text-slate-700">{{ talent?.Job_Position }}</h2>
                <p class="w-full text-left px-5 text-sm font-light text-slate-700">
                    {{ talent.Bio ? talent.Bio : "Lorem llum explicabo qui c " }}
                </p>
                <div class="w-full px-5 flex flex-row justify-center items-center gap-2">
                    <button title="click to copy phone" @click="copyText(talent?.Phone)" class="text-slate-700 w-20 h-20 bg-transparent"><font-awesome-icon :icon="['fas', 'phone']" /></button>
                    <button title="click to copy email" @click="copyText(talent?.Email)" class="text-slate-700 w-20 h-20 bg-transparent"><font-awesome-icon :icon="['fas', 'envelope']" /></button>
                    <router-link class="text-slate-700 w-20 h-20 bg-transparent flex items-center justify-center" :to="{ path: talent?.linkedIn }"
                        ><font-awesome-icon :icon="['fab', 'linkedin']" />
                    </router-link>
                    <router-link class="text-slate-700 w-20 h-20 bg-transparent flex items-center justify-center" :to="{ path: talent?.link ? talent?.link : '/' }"
                        ><font-awesome-icon :icon="['fas', 'globe']"
                    /></router-link>
                </div>
                <button
                    class="w-6 p-4 h-6 rounded-full flex justify-center items-center border border-slate-200 opacity-0 group-hover:opacity-100 bg-white text-slate-400 absolute top-2 right-2"
                    @click="flipped = !flipped"
                >
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
            <div
                v-else
                :key="talent.Email + '_back'"
                class="w-full h-full rounded-lg pb-2 bg-white will-change-transform relative overflow-hidden flex flex-col items-start justify-between group hover:scale-105 duration-1000 transition-all ease-out shadow-card inner"
            >
                <h1 class="w-full text-center px-5 text-3xl font-semibold text-slate-700 mt-6 mb-3">{{ talent?.First_name + " " + talent?.Last_name }}</h1>
                <!-- <div class="flex flex-row justify-between items-center w-full px-5 gap-1">
                    <div class="flex w-1/2 flex-row items-center px-5 justify-start gap-4 text-xs font-light "><font-awesome-icon :icon="['fas', 'list-check']" /><h2>{{ talent?.Status===""? 'Passed':talent?.Status  }}</h2></div>
                    <div class="flex w-1/2 flex-row items-center px-5 justify-start gap-4 text-xs font-light "><font-awesome-icon :icon="['far', 'clock']" /><h2>{{getDateAndTime(candidate.createdAt) || '-'}}</h2></div>
                </div> -->

                <div class="flex flex-col w-full px-5 justify-between items-center gap-6">
                    <h2
                        class="w-full relative rounded-md p-3 px-5 border border-slate-400 text-slate-700 text-left text-base font-light after:absolute after:px-2 position after:w-fit after:bg-white after:text-sm after:text-slate-700 after:-top-4 after:left-2"
                    >
                        {{ getProjectName(candidate?.projectId) }}
                    </h2>
                    <h2
                        class="w-full flex flex-row flex-wrap gap-2 justify-start items-center relative rounded-md p-3 px-5 border border-slate-400 text-slate-700 text-left text-xs font-light after:absolute after:px-2 assessments after:w-fit after:bg-white after:text-sm after:text-slate-700 after:-top-4 after:left-2"
                    >
                        <span v-for="assess in candidate.results" :key="assess.assessmentName" class="px-3 py-2 rounded-full border border-slate-300">{{ assess.assessmentName }}</span>
                    </h2>
                </div>
                <div class="flex flex-row justify-between items-center gap-10 w-full px-5">
                    <button class="w-1/2 text-base p-3 font-bold rounded-md shadow-[0_0_8px_2px_rgba(0,0,0,15%)] bg-white text-NeonBlue border border-slate-200">Archive</button>
                    <button
                        class="w-1/2 text-base p-3 font-bold rounded-md bg-NeonBlue text-white"
                        @click="
                            () => {
                                this.Store.candidateInfoAct = talent;
                                this.Store.candidateActivity = candidate;
                                this.$router.push({
                                    path: '/candidate',
                                    query: { email: talent.Email, projectId: candidate?.projectId },
                                });
                            }
                        "
                    >
                        Full report
                    </button>
                </div>
                <button
                    class="w-6 p-4 h-6 rounded-full flex justify-center items-center border border-slate-200 opacity-0 group-hover:opacity-100 bg-white text-slate-400 absolute top-2 left-2"
                    @click="flipped = !flipped"
                >
                    <font-awesome-icon :icon="['fas', 'arrow-left']" />
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import { useStore } from "@/store";
import axios from "axios";
export default {
    name: "TalentCard",
    props: ["talent", "candidate"],
    data() {
        return {
            flipped: false,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        getImgUrl(/* imgFileId */) {
            // if (imgFileId) {
            //     var image = `data:image/png;base64,${imgFileId}`;
            //     console.log({ image });
            //     // return image;
            // }
            // return require("@/assets/our_company.jpg");
            return require("@/assets/GO_logo.svg");
        },
        flipCard() {
            setTimeout(() => {
                this.flipped = false;
            }, 3000);
        },
        copyText(txt) {
            navigator.clipboard
                .writeText(txt)
                .then(() => {
                    console.log(`${txt} copied`);
                })
                .catch((err) => console.error("Couldn't copy, error occured", err));
        },
        getProjectName(projectId) {
            const project = this.Store.projects.find((project) => project._id === projectId);
            return project?.name;
        },
        getDateAndTime(dateString) {
            const date = new Date(dateString);

            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${parseInt(hours) + 1}:${minutes}:${seconds}`;
            return formattedDateTime;
        },
        archiveCandidate() {
            this.toggleMenu();
            if (this.candidate?.status === "archived") {
                this.toggleLoading();
            }
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/candidates/switchStatus/${this.candidate._id}`,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then(async () => {
                    if (this.candidateStatus === "active") {
                        await this.getCandidates();
                    } else {
                        await this.Store.getArchivedCandidates().then(() => {
                            this.toggleLoading();
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.position::after {
    content: "Position";
}

.assessments::after {
    content: "Assessments";
}

.inner {
    transform-style: preserve-3d;
}

.flip-enter-active {
    transition: transform 0.5s ease;
    opacity: 0;
}

.flip-leave-active {
    transition: transform 0.5s ease;
    // opacity: 0;
}

.flip-enter-from,
.flip-leave-to {
    transform: rotateY(180deg);
    opacity: 1;
}

.flip-enter-from {
    opacity: 0;
}
</style>
